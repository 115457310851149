// 路由器配置文件
import VueRouter from 'vue-router';


// 引入组件
import DomainHealth from "@/pages/DomainHealth.vue";
import DomainMonitor from "@/pages/DomainMonitor.vue"
import TaiwanTable from "@/pages/tables/TaiwanTable.vue";
import HongKongTable from "@/pages/tables/HongKongTable.vue";
import MalaysiaTable from "@/pages/tables/MalaysiaTable.vue";

export default new VueRouter({
    routes:[
        {
            path:'',
            redirect:'/health'
        },
        {
            path:'/health',
            component:DomainHealth
        },
        {
            path:'/monitor',
            component:DomainMonitor,
            children:[
                {
                  path:'',
                  redirect:'/monitor/taiwan'  
                },
                {
                    path:'taiwan',
                    component:TaiwanTable
                },
                {
                    path:'hongkong',
                    component:HongKongTable
                },
                {
                    path:'malaysia',
                    component:MalaysiaTable
                }
            ]
        }
    ]
})