<template>
    <div class="monitorheader">
        <!-- 地区导航 -->
        <div class="areas">
            <router-link to="/monitor/taiwan" class="area" active-class="isactive" ><div @click="area = '台湾'">台湾</div></router-link>
            <router-link to="/monitor/hongkong" class="area" active-class="isactive"><div @click="area = '香港'">香港</div></router-link>
            <router-link to="/monitor/malaysia" class="area" active-class="isactive"><div @click="area = '马来'">马来</div></router-link>
        </div>
        
        <!-- 查询参数 -->
        <div class="data-params">
            <h3><span style="color: #B08BBB;">{{ area }}</span>参数查询</h3>
            <div class="count">当前表格数据：{{tablecount}}条</div>
            <div class="time-frame">
                <BeginTime/>
                <EndTime/>
            </div>
            <div class="event">
                <span>事件类型: </span>
                <div class="btn" @click="changeEvent('')" :class="{'isactive':$store.state.event === ''}">全部</div>
                <div class="btn" @click="changeEvent('页面访问')" :class="{'isactive':$store.state.event === '页面访问'}">页面访问</div>
                <div class="btn" @click="changeEvent('添加line')" :class="{'isactive':$store.state.event === '添加line'}">添加line</div>
            </div>
            <div class="btn" @click="queryDate">查询</div>
        </div>

        <!-- 数据总览 -->
        <div class="data-screen">
            <h3><span style="color: #B08BBB;">{{ area }}</span>数据总览</h3>
            <div class="today-line">今日<span style="color: #B08BBB;">{{ area }}</span>总line量：{{ todayline }}</div>
            <div class="today-page">今日<span style="color: #B08BBB;">{{ area }}</span>总访问量：{{ todaypage }}</div>
            <div class="yesterday-line">昨日<span style="color: #B08BBB;">{{ area }}</span>总line量：{{ yesterdayline }}</div>
            <div class="yesterday-page">昨日<span style="color: #B08BBB;">{{ area }}</span>总访问量：{{ yesterdaypage }}</div>
            <div class="seven-line">本周<span style="color: #B08BBB;">{{ area }}</span>总line量：{{ weekline }}</div>
            <div class="seven-page">本周<span style="color: #B08BBB;">{{ area }}</span>总访问量：{{ weekpage }}</div>
        </div>
    </div>
</template>

<script>
import BeginTime from './BeginTime.vue';
import EndTime from './EndTime.vue';


export default {
    data(){
        return{
            area:'台湾'  
        }
    },
    components:{
        BeginTime,
        EndTime
    },
    methods:{
        // 事件参数
        changeEvent(param){
            this.$store.commit('GETEVENT',param)
        },

        // 更新数据
        queryDate(){
            if(this.area === '台湾'){
                this.$store.dispatch('getTaiwanData')
            }else if(this.area === '香港'){
                this.$store.dispatch('getHongKongData')
            }else{
                this.$store.dispatch('getMalaysiaData')
            }
        }
    },
    computed:{
        tablecount(){
            if(this.area === '台湾'){
                return this.$store.getters.taiwanLength
            }else if(this.area === '香港'){
                return this.$store.getters.hongkongLength
            }else{
                return this.$store.getters.malaysiaLength
            }
        },

        // 访问量
        todaypage(){
            if(this.$store.state.datascreen){
                if(this.area === '台湾'){
                    return this.$store.state.datascreen.taiwan.todaypage
                }else if(this.area === '香港'){
                    return this.$store.state.datascreen.hongkong.todaypage
                }else{
                    return this.$store.state.datascreen.malaysia.todaypage
                }
            }
            return '';
        },
        yesterdaypage(){
            if(this.$store.state.datascreen){
                if(this.area === '台湾'){
                    return this.$store.state.datascreen.taiwan.yesterdaypage
                }else if(this.area === '香港'){
                    return this.$store.state.datascreen.hongkong.yesterdaypage
                }else{
                    return this.$store.state.datascreen.malaysia.yesterdaypage
                }
            }
            return '';
        },
        weekpage(){
            if(this.$store.state.datascreen){
                if(this.area === '台湾'){
                    return this.$store.state.datascreen.taiwan.weekpage
                }else if(this.area === '香港'){
                    return this.$store.state.datascreen.hongkong.weekpage
                }else{
                    return this.$store.state.datascreen.malaysia.weekpage
                }
            }
            return '';
        },

        // 添加line量
        todayline(){
            if(this.$store.state.datascreen){
                if(this.area === '台湾'){
                    return this.$store.state.datascreen.taiwan.todayline
                }else if(this.area === '香港'){
                    return this.$store.state.datascreen.hongkong.todayline
                }else{
                    return this.$store.state.datascreen.malaysia.todayline
                }
            }
            return '';

        },
        yesterdayline(){
            if(this.$store.state.datascreen){
                if(this.area === '台湾'){
                    return this.$store.state.datascreen.taiwan.yesterdayline
                }else if(this.area === '香港'){
                    return this.$store.state.datascreen.hongkong.yesterdayline
                }else{
                    return this.$store.state.datascreen.malaysia.yesterdayline
                }
            }
            return '';
        },
        weekline(){
            if(this.$store.state.datascreen){
                if(this.area === '台湾'){
                    return this.$store.state.datascreen.taiwan.weekline
                }else if(this.area === '香港'){
                    return this.$store.state.datascreen.hongkong.weekline
                }else{
                    return this.$store.state.datascreen.malaysia.weekline
                }
            }
            return '';
        }
    }
}
</script>

<style lang="less" scoped>
    .monitorheader{
        width: 100%;
        min-width: 520px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        // 地区选择
        .areas{
            width: 100%;
            height: 60px;
            background-color: #F5f5f5;
            border-radius: 4px;
            border: solid 1px #ddd;
            display: flex;
            justify-content: space-between;
            align-items: center;
            overflow: hidden;
            .area{
                color: #222;
                flex-grow: 1;
                line-height: 60px;
                text-align: center;
                cursor: pointer;
                font-size: 19px;
            }
            .isactive{
                background-color: #B08BBB;
                color: #fff;
            }
        }

        // 参数选择
        .data-params{
            width: 100%;
            margin-top: 20px;
            background-color: #f5f5f5;
            border-radius: 4px;
            padding: 10px;

            h3{
                width: 100%;
                text-align: center;
                line-height: 60px;
            }
            .count{
                width: 100%;
                height: 40px;
                line-height: 40px;
                border: solid 1px #ddd;
                border-radius: 4px;
                margin-bottom: 10px;
                padding-left: 10px;
            }
            .time-frame{
                display: flex;
                justify-content: space-between;
            }
            .event{
                width: 100%;
                height: 40px;
                margin-top: 10px;
                line-height: 40px;
                display: flex;
                border: solid 1px #ddd;
                padding-left: 10px;
                user-select: none;
                border-radius: 4px;
                span{
                    margin-right: 10px;
                }
                .btn{
                    flex-grow: 1;
                    text-align: center;
                    cursor: pointer;
                }
                .isactive{
                    background-color: #B08BBB;
                    color: #fff;
                }
            }

            &>.btn{
                width: 100%;
                height: 40px;
                line-height: 38px;
                text-align: center;
                border: solid 1px #ddd;
                margin-top: 10px;
                border-radius: 4px;
                cursor: pointer;
                user-select: none;
                background-color: #B08BBB;
                color: #fff;
            }
        }

        // 数据总览
        .data-screen{
            width: 100%;
            background-color: #f5f5f5;
            padding: 10px;
            margin-top: 20px;

            h3{
                width: 100%;
                text-align: center;
                line-height: 60px;
            }

            .today-page{
                width: 100%;
                height: 40px;
                border: solid 1px #ddd;
                line-height: 38px;
                padding-left: 10px;
                margin-top: 10px;
            }

            .today-line{
                width: 100%;
                height: 40px;
                border: solid 1px #ddd;
                line-height: 38px;
                padding-left: 10px;
            }
            .yesterday-page{
                width: 100%;
                height: 40px;
                border: solid 1px #ddd;
                line-height: 38px;
                padding-left: 10px;
                margin-top: 10px;
            }
            .yesterday-line{
                width: 100%;
                height: 40px;
                border: solid 1px #ddd;
                line-height: 38px;
                padding-left: 10px;
                margin-top: 20px;
            }
            .seven-page{
                width: 100%;
                height: 40px;
                border: solid 1px #ddd;
                line-height: 38px;
                padding-left: 10px;
                margin-top: 10px;
            }
            .seven-line{
                width: 100%;
                height: 40px;
                border: solid 1px #ddd;
                line-height: 38px;
                padding-left: 10px;
                margin-top: 20px;
            }
        }
    }
</style>