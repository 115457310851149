// 引入vue、vuex
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import axios from 'axios';

// 三个重要对象
const actions = {
    // 台湾表格数据
    getTaiwanData(context){
        axios.get('https://home.nonpage.com/index.php/api/query/index',{
            params:{
                area:'TW',
                limit:10000,
                beginTime:context.state.begintime,
                endTime:context.state.endtime,
                event:context.state.event
            }
        }).then(res=>{
            context.commit('GETTAIWANDATA',res.data)
        })
    },
    // 香港表格数据
    getHongKongData(context){
        axios.get('https://home.nonpage.com/index.php/api/query/index',{
            params:{
                area:'HK',
                limit:10000,
                beginTime:context.state.begintime,
                endTime:context.state.endtime,
                event:context.state.event
            }
        }).then(res=>{
            context.commit('GETHONGKONGDATA',res.data)
        })
    },
    // 马来表格数据
    getMalaysiaData(context){
        axios.get('https://home.nonpage.com/index.php/api/query/index',{
            params:{
                area:'MY',
                limit:10000,
                beginTime:context.state.begintime,
                endTime:context.state.endtime,
                event:context.state.event
            }
        }).then(res=>{
            context.commit('GETMALAYSIADATA',res.data)
        })
    },

    // 总览数据
    getDataScreen(context){
        axios.get('https://home.nonpage.com/index.php/api/query/datacount',{
            params:{
                'auth':'yimeiqi'
            }
        }).then(res=>{
            context.commit('GETDATASCREEN',res.data);
        })
    },

    // 域名健康表格
    getTableHealthData(context){
        axios.get('https://home.nonpage.com/index.php/api/query/health?auth=yimeiqi').then(res=>{
        // this.tabledata = res.data
            context.commit('GETTABLEHEALTHDATA',res.data);
      })
    }
};

const mutations = {
    // 台湾表格数据
    GETTAIWANDATA(state,value){
        if(!value.code){
            return
        }
        state.taiwanData = value.data
    },
    // 香港表格数据
    GETHONGKONGDATA(state,value){
        if(!value.code){
            return
        }
        state.hongkongData = value.data
    },
    // 马来表格数据
    GETMALAYSIADATA(state,value){
        if(!value.code){
            return
        }
        state.malaysiaData = value.data
    },
    // 开始时间数据
    GETBEGINTIME(state,value){
        state.begintime = value
    },
    // 结束时间数据
    GETENDTIME(state,value){
        state.endtime = value
    },
    // 事件类型
    GETEVENT(state,value){
        state.event = value
    },
    // 数据总览
    GETDATASCREEN(state,value){
        state.datascreen = value
    },
    // 域名健康表格
    GETTABLEHEALTHDATA(state,value){
        state.tableHealthData = value;
    }
};

const state = {
    taiwanData:'',
    hongkongData:'',
    malaysiaData:'',
    begintime:'',
    endtime:'',
    event:'',
    datascreen:'',
    tableHealthData:''
};

const getters = {
    taiwanLength(state){
        return state.taiwanData.length
    },
    hongkongLength(state){
        return state.hongkongData.length
    },
    malaysiaLength(state){
        return state.malaysiaData.length
    }
}

export default new Vuex.Store({
    actions,
    mutations,
    state,
    getters
})

