import Vue from 'vue'
import App from './App.vue'

// 引入路由器
import VueRouter from "vue-router";
import router from "./router/index.js"

// 引入store
import store from "@/store/index.js"


// 引入element-ui
import { DatePicker, TimePicker, Button } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';


Vue.config.productionTip = false

// 注册
Vue.use(VueRouter);
Vue.use(DatePicker);
Vue.use(TimePicker);
Vue.use(Button);

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
