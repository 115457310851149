<template>
  <div class="health">
    <div class="table">
      <table>
        <thead>
          <tr>
            <th>索引</th>
            <th>一级域名.顶级域名</th>
            <th>域名健康</th>
            <th>更新时间<span style="color: #fff; font-size: 12px;">(半小时更新一次)</span></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item) of $store.state.tableHealthData" :key="item.id">
            <td>{{ item.id }}</td>
            <td>{{ item.domain }}</td>
            <td :class="{'isBlack': item.domain_health === '台湾屏蔽'}">{{ item.domain_health }}</td>
            <td>{{ item.createtime }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  
}
</script>

<style lang="less" scoped>
    .health{
      width: 100%;
      height: 100%;
      padding: 20px;
      &>.table{
        width: 600px;
        height: 100%;
        overflow-y: auto;
        border: 3px solid #ddd;
        border-radius: 6px;
        table{
          width: 100%;
          border-collapse: collapse;

          thead{
            position: sticky;
            color: #fff;
            top: 0;
            background-color: #B08BBB;
            tr{
                th{
                    border: solid 3px #ddd;
                    border-top: none;
                    border-bottom: none;
                    padding: 8px 4px;
                }
            }
          }

          tbody{
            tr{
              td{
                border: solid 3px #ddd;
                padding: 2px 4px;
              }
              .isBlack{
                background-color: #C70039;
                color: #FFF;
              }
            }
          }
        }
      }
    }
</style>