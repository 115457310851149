<template>
  <YimeiqiPage/>
</template>

<script>
import YimeiqiPage from './components/YimeiqiPage.vue';


export default {
  name: 'App',
  components:{
    YimeiqiPage
  }
}
</script>

<style>
  *{
    margin: 0;
    padding: 0;
    text-decoration: none;
    box-sizing: border-box;
    font-family: "PingFang SC", "Source Han Sans", "Microsoft Yahei", sans-serif;
    white-space: nowrap;
  }
</style>
