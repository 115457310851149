<template>
    <div class="taiwanTable" @scroll="showlist($event)" ref="overtable">
      <table>
          <thead>
              <tr>
                  <th>时间</th>
                  <th style="max-width: 240px;">域名</th>
                  <th>域名健康</th>
                  <th>ip</th>
                  <th style="max-width: 150px;">ip归属地</th>
                  <th>事件</th>
              </tr>
          </thead>
          <tbody>
              <tr :style="{height:topheight}"></tr>
              <tr v-for="(item) of $store.state.malaysiaData.slice(start,over)" :key="item.id">
                  <td>{{ item.createtime }}</td>
                  <td style="max-width: 240px; overflow: hidden;">{{ item.domain }}</td>
                  <td :class="{isHealth: item.domain_health === '台湾屏蔽',isNothing: item.domain_health === '暂无数据'}">{{ item.domain_health }}</td>
                  <td>{{ item.ip }}</td>
                  <td style="max-width: 150px; overflow: hidden;">{{ item.ip_addr }}</td>
                  <td :class="{isLine: item.event === '添加line'}">{{ item.event }}</td>
              </tr>
              <tr :style="{height:bottomheight}"></tr>
          </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import _ from 'lodash';
  
  export default {
      data(){
          return{
              start:0,
              over:250,
              topheight:'0px',
              bottomheight:`${(this.$store.state.malaysiaData.length - 250)*30}px`,
              delaytop:0
          }
      },
      methods:{
          showlist: _.throttle(function(e){
  
              // if((e.target.scrollTop + (e.target.innerHeight || e.target.clientHeight) + 100) >= e.target.scrollHeight){
              //     this.over += 300
              //     console.log('hello')
              // }
  
              if(Math.abs(e.target.scrollTop - this.delaytop) >= 100*30 ){
                  this.start = Math.max(Math.floor(e.target.scrollTop / 30 - 110),0)
                  this.over = Math.min((this.start + 250),this.$store.state.malaysiaData.length)
  
                  this.topheight = `${this.start*30}px`;
                  this.bottomheight = `${(this.$store.state.malaysiaData.length - this.over)*30}px`
  
                  this.delaytop = e.target.scrollTop
  
                  // console.log(e.target.scrollHeight)
                  // console.log(this.topheight)
                  // console.log(this.bottomheight)
                  // console.log(this.start)
                  // console.log(this.over)
                  // console.log(this.$store.state.malaysiaData.length)
                  // console.log("-----")
              }
          },200),
      },
  }
  </script>
  
  <style lang="less" scoped>
  .taiwanTable{
      width: 100%;
      height: 100%;
      overflow-y: auto;
      border-top: 3px solid #ddd;
      border-right: 3px solid #ddd;
      border-bottom: 3px solid #ddd;
      border-radius: 6px;
      &>table{
          width: 100%;
          table-layout: fixed;
          border-collapse: collapse;
          &>tbody{
              tr{
                  td{
                      border: solid 3px #ddd;
                      height: 30px;
                      padding-left: 5px;
                  }
                  // 台湾屏蔽样式
                  .isHealth{
                      background-color: #C70039;
                      color: #FFF;
                  }

                  // 暂无数据样式
                  .isNothing{
                      background-color: #FD8D14;
                      color: #fff;
                  }

                  // 添加line样式
                  .isLine{
                      background-color: #B08BBB;
                      color: #fff;
                  }

                  &>td:nth-child(1){
                      width: 190px;
                      overflow: hidden;
                  }
                  &>td:nth-child(2){
                      width: 240px;
                      overflow: hidden;
                  }
                  &>td:nth-child(3){
                      width: 85px;
                      overflow: hidden;
                  }
                  &>td:nth-child(4){
                      width: 150px;
                      overflow: hidden;
                  }
                  &>td:nth-child(5){
                      width: 150px;
                      overflow: hidden;
                  }
                  &>td:nth-child(6){
                      width: 80px;
                      overflow: hidden;
                  }
              }
          }

          &>thead{
              position: sticky;
              color: #fff;
              top: 0;
              background-color: #B08BBB;
              tr{
                  th{
                      border: solid 3px #ddd;
                      border-top: none;
                      border-bottom: none;
                      padding: 8px 4px;
                  }
                  &>th:nth-child(1){
                      width: 190px;
                      overflow: hidden;
                  }
                  &>th:nth-child(2){
                      width: 240px;
                      overflow: hidden;
                  }
                  &>th:nth-child(3){
                      width: 85px;
                      overflow: hidden;
                  }
                  &>th:nth-child(4){
                      width: 150px;
                      overflow: hidden;
                  }
                  &>th:nth-child(5){
                      width: 150px;
                      overflow: hidden;
                  }
                  &>th:nth-child(6){
                      width: 80px;
                      overflow: hidden;
                  }
              }
          }
      }
  }
</style>