<template>
  <div class="monitor">
    <!-- 左边表格数据 -->
    <div class="monitor-left" >
      <router-view v-if="$store.state.taiwanData.length"></router-view>
    </div>

    <!-- 右边导航和总览 -->
    <div class="monitor-right">
      <MonitorHeader/>
    </div>
  </div>
</template>

<script>
import MonitorHeader from '@/components/MonitorHeader.vue';

export default {
  components:{
    MonitorHeader,
  }
}
</script>

<style lang="less" scoped>
  .monitor{
    width: 100%;
    height: 100%;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    .monitor-left{
      width: 920px;
    }
    .monitor-right{
      width: calc(100% - 940px);
      height: 100%;
    }
  }
</style>