<template>
    <div class="block">
      <el-date-picker
        v-model="value"
        type="datetime"
        placeholder="选择开始时间"
        default-time="00:00:00">
      </el-date-picker>
    </div>
  </template>
  
  <script>
    export default {
      data() {
        return {
            value:''
        };
      },
      watch:{
        value(newValue){
            if(newValue){
              this.$store.commit('GETBEGINTIME',this.formatDate(newValue));
            }else{
              this.$store.commit('GETBEGINTIME',newValue);
            }
        }
      },
      methods:{
        formatDate(date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const day = String(date.getDate()).padStart(2, "0");
            const hours = String(date.getHours()).padStart(2, "0");
            const minutes = String(date.getMinutes()).padStart(2, "0");
            const seconds = String(date.getSeconds()).padStart(2, "0");

            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        }
      }
    };
  </script>